@font-face {
    font-family: HelveticaNeueBold;
    font-style: normal;
    font-weight: bold;
    src: url(./HelveticaNeue-Bold.otf) format("OpenType");
    /* font-display: fallback; */
}

@font-face {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: normal;
    src: url(./HelveticaNeue-Regular.otf) format("OpenType");
    /* font-display: fallback; */
}

@font-face {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: normal;
    src: url(./BentonSans-Regular.otf) format("OpenType");
    /* font-display: fallback; */
}