.cryptoPage {
  padding-bottom: 50px;

  .cryptoWrapper {
    max-width: 1450px !important;
    background-color: #fff;
    margin-top: -150px;
    position: relative;
    padding: 50px;
  }

  .gridsImg {
    margin: 0 auto;
    display: block;
    margin-bottom: 50px;
    width: 100%;
    max-width: 630px;
  }

  .colLeft {
    padding-top: 40px;

    .ticketImg {
      width: 100%;
    }
  }

  .dateRapper {
    display: flex;
    flex-direction: column;
    color: #0067a1;
    padding: 30px 0;
    justify-content: center;

    * {
      text-align: center;
    }

    .date {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 100;
      font-size: 36px;
      line-height: 42px;
      padding-left: 10px;
    }

    label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 7px auto;
    }

    .time {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .alertWrapper {
    a {
      background: #0067a1;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      text-decoration: none;
      color: #fff;
      max-width: 638px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0 auto;
      margin-top: 70px;
      padding: 10px;

      img {
        margin-left: 30px;
      }
    }
  }

  .title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #0067a1;
    margin-bottom: 10px !important;
  }

  .subTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #0067a1;
    max-width: 635px;
    margin-bottom: 50px !important;
  }

  .crypto {
    h2 {
      margin: 0;
    }
  }

  .gridWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    margin-bottom: 15px;
    padding-top: 20px;
  }

  .titleSeats {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0067a1;
  }

  .girdContainer {
    display: grid;
    margin-bottom: 15px;

    .imageContainer {
      font-size: smaller;
      position: relative;
      text-align: center;
      color: white;

      .tooltip {
        visibility: hidden;
        min-width: 70px;
        background-color: #0067a1;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: -15px;
        left: 105%;
      }

      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pointer {
    cursor: pointer;
  }

  .buttonBuy {
    width: 115px;
    height: 41px;
    background: #47863c;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    display: block;
    margin: 25px auto;
  }

  .selectedSeatsWrapper {
    background: rgba(0, 103, 161, 0.04);
    border: 1px solid #0067a1;
    box-sizing: border-box;
    border-radius: 10px;
    color: #0067a1;
    width: 100%;
    text-align: center;
    padding: 20px;

    h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 15px auto 10px;
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: block;
    }

    .price {
      margin-top: 30px;
    }
  }

  @media (min-width: 1440px) {
    .container {
      max-width: 1440px;
    }

    .girdContainer {
      grid-template-columns: repeat(8, 50px);
      gap: 5px;

      &:nth-child(2n-1) {
        width: 500px;
      }

      &:nth-child(2n) {
        width: 450px;
      }
    }
  }

  @media (max-width: 1439px) {
    .gridWrapper {
      justify-items: center;

      &r::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0067a1;
        border-radius: 10px;
      }
    }

    .cryptoWrapper {
      padding: 20px;
    }

    .girdContainer {
      grid-template-columns: repeat(8, 40px);
      gap: 1px;

      &:nth-child(2n-1) {
        width: 350px;
      }

      &:nth-child(2n) {
        width: 330px;
      }

      .imageContainer {
        svg {
          width: 30px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .crypto {
      margin-top: -50px;
    }

    .cryptoWrapper {
      margin-top: 0;
    }

    .ticketWrapper {
      text-align: center;
    }

    .colLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .cryptoWrapper {
      margin-top: -30px;

      .ticketImg {
        border: 5px solid #fff;
      }
    }
  }

  @media (max-width: 991px) {
    .gridWrapper {
      overflow-x: auto;
    }
  }

  @media (max-width: 767px) {
    .girdContainer {
      grid-template-columns: repeat(8, 40px);
      gap: 1px;
      margin-bottom: 15px;

      &:nth-child(2n-1) {
        width: 350px;
      }

      &:nth-child(2n) {
        width: 330px;
      }

      .imageContainer {
        svg {
          width: 30px;
        }
      }
    }

    .alertWrapper {
      a {
        height: auto;
      }
    }
  }
}

.walletIcon {
  width: "15px";
  height: "15px";
}

.disableBuy {
  background-color: "#bbbbbb";
  color: white;
}
