@import '../../assets/theme/variables.scss';

.favoriteSeat {
  max-width: 920px;
  position: relative;
  margin: -155px auto 90px;
  display: flex;

  .colLeft {
    background-color: #0067A1;
    color: #fff;
    flex: 1;
    padding: 40px;
    font-family: 'Roboto';

    span {
      font-weight: 100;
      font-size: 64px;
      line-height: 75px;
    }

    h4 {
      font-weight: 700;
      font-size: 55px;
      line-height: 64px;
      text-align: center;
      margin: 20px auto;
    }

    label {
      font-weight: 100;
      font-size: 36px;
      line-height: 42px;
      text-align: right;
      display: block;
    }
  }

  .colRight {
    background-color: #fff;
    color: #0067A1;
    flex: 1;
    padding: 40px;
    font-family: 'Roboto';

    span {
      font-weight: 100;
      font-size: 36px;
      line-height: 42px;
      margin: 20px 0 30px;
      display: block;
    }

    h4 {
      font-weight: 700;
      font-size: 55px;
      line-height: 64px;
      text-align: center;
      margin: 20px auto;
      display: block;
    }

    label {
      font-weight: 100;
      font-size: 36px;
      line-height: 42px;
      display: block;
      text-align: right;
      margin: 10px 35px 0;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
    margin: -20px 0 0;
  }
}