.homePage {
  .cardTicketsWrapper {
    padding: 50px 0 100px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    .cardTicketsWrapper {
      padding: 50px 0 40px;
    }
  }
}