@import "../../assets/theme/variables.scss";

.cardTickets {
  margin-bottom: 48px;
  color: #0067a1;
  display: flex;
  background-color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  .colLeft {
    padding-right: 10px;
  }

  .titleWrapper {
    max-width: 670px;
    flex: 1;
    padding: 10px 30px 10px 10px;

    h2 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      margin: 0;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      font-family: "Roboto";
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 5px;
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .dateTimeWrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;

    img {
      margin-top: 5px;
    }

    .dateWrapper {
      padding-left: 14px;

      .date {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 100;
        font-size: 36px;
        line-height: 42px;
      }

      h5 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 10px;
      }

      .time {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .chairWrapper {
    padding-top: 20px;
    display: flex;
    align-items: center;

    button {
      width: 101px;
      height: 41px;
      background-color: #47863c;
      color: #fff;
      margin-left: 20px;
      border: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    span {
      width: 60px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      display: inline-block;
    }

    svg {
      margin-right: 10px;
    }
  }

  &.isFull {
    .chairWrapper {
      color: #bbbbbb;
      button {
        background-color: #bbbbbb;
      }
    }

    svg {
      path {
        fill: #bbbbbb;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;

    .imgTicket {
      width: 100%;
    }

    .titleWrapper {
      padding: 20px;
    }

    .colLeft {
      padding: 20px;
    }
  }
}
