@import "../../../assets/theme/variables.scss";

.header {
  max-height: 574px;

  .titleWrapper {
    position: relative;

    .headerContent {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      padding: 0 5%;
      z-index: 1;
    }

    .title {
      width: 100%;
      margin: 10px 0 0;

      a {
        color: $white-color;
        font-size: 55px;
      }

      // font: normal normal normal 18px/22px $primary-font;
    }

    .connectionWrapper {
      display: flex;
      align-items: center;

      .message {
        background: rgb(32 62 81 / 42%);
        // height: 40px;
        display: flex;
        align-items: center;
        padding: 5px 20px;
        border-radius: 5px;

        p {
          color: #fff;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          white-space: nowrap;
          margin: 0;
        }
      }

      .buttonBuy {
        background: rgb(255 255 255 / 92%);
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #0067A1;
        padding: 10px 15px;
        white-space: nowrap;
        border-radius: 5px;
        height: 40px;
      }
    }
  }

  img {
    width: 100%;
    max-height: 574px;
  }

  @media (max-width: 767px) {
    height: 149px;

    .headerContent {
      flex-direction: column;

      .title {
        a {
          font-size: 35px;
        }
      }
    }

  }
}

a {
  text-decoration: none;
}