@import '../../../assets/theme/variables.scss';

.footer {
  height: 77px;
  background-color: $primary;
  color: #fff;

  .footerContent {
    display: flex;
    align-items: center;
    height: 77px;
  }

  label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-left: auto;
    display: inline-block;
  }

  @media (max-width: 767px) {
    height: auto;

    .footerContent {
      flex-direction: column;
      align-items: center;
      height: auto;
      padding: 10px;

      span {
        margin: 10px auto;
      }
    }
  }
}